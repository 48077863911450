import React from 'react';
import { Link } from 'gatsby';
import { useUser } from '../hooks/user';
import AcademyOfBarmyComposersFooter from './academyOfBarmyComposersFooter';

const UserPage = ({ children, title }) => {
  const { username, organisationName } = useUser();

  return (
    <div className="user-page-container">
      <div className="user-page-container__header">
        <div className="admin-page-container__header-nav-left"/>
        <div className="user-page-container__header-title-container">
          {title ? <h1 className="user-page-container__header-title">{title}</h1> : null}
        </div>
        <div className="user-page-container__header-user-container">
          <span className="user-page-container__header-user-container-item">{username}</span>
          <span className="user-page-container__header-user-container-item">{organisationName}</span>
          <Link className="user-page-container-link" to="/login">Logout</Link>
        </div>
      </div>
      <div className="user-page-body">
        {children}
      </div>
      <AcademyOfBarmyComposersFooter />
    </div>
  );
};

export default UserPage;