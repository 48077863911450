const BASE_GOOGLE_DOCS_URL = 'https://drive.google.com';

export const googleSlidesFormat = new RegExp('https://docs.google.com/presentation/d/(.+?)/', 'i');
export const googleDocsFormat = new RegExp('https://drive.google.com/file/d/(.+?)/', 'i');

export const formatForDownloadGoogleDriveURL = (url) => {
  // https://drive.google.com/file/d/1VhHUq0kjoWRD9gKlvu059TEzMKXvLgp-/view
  try {
    const id = url.split('/').reverse()[1];
    return `${BASE_GOOGLE_DOCS_URL}/uc?export=view&id=${id}`;
  } catch (e) {
    console.error('URL not recognised as google docs url', url, e);
  }
  return url;
};