import React, { Fragment, memo, useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getResourceText } from '../../lib/language';
import Loading, { LoadingSpinner, LoadingText } from '../../components/loading';
import UserPage from '../../components/userPage';
import { useApi } from '../../hooks/api';
import { formatForDownloadGoogleDriveURL } from '../../lib/url';
import { sortResourcesByName } from '../../lib/sort';

const dashboardResourcesImageAltText = [
  'Wagner',
  'Rossini',
  'Tchaikovsky',
  'Chevalier',
  'Mozart',
  'Luckless Lully',
  'Handel',
];

const SectionLinks = memo(({ sections, resource }) => {
  const data = useStaticQuery(query);

  return (
    <div className="section-links-container">
      {sections.filter(Boolean).map(({ resourceName, resourceId: sectionId }, index) =>
        <Link to={`/teacher-resources/${resource.resourceId}/sections/${sectionId}`} key={resourceName}
              className={`section-resource section-resource_${index % 7}`}>
          <GatsbyImage
            className={`section-resource__image-${(index + 1) % 7}`}
            image={getImage(data[`dashboardResourcesImage${(index + 1) % 7}`])}
            alt={dashboardResourcesImageAltText[(index + 1) % 7]}/>
          <span className="section-resource__name">{resourceName}</span>
        </Link>)}
    </div>
  );
});

const getAllSections = async ({ fetchFunction, resourceId, sectionIds }) => {
  return await Promise.all(sectionIds.map(async (sectionId) => {
    const { status, json } = await fetchFunction({ resourceId, sectionId });

    if (status === 200) {
      return json;
    }
  }));
};

const ResourceError = memo(() => (
  <div className="sections-container__no-resources">
    <h2 className="sections-container__sub-heading">{getResourceText('sectionError')}</h2>
    <p>{getResourceText('sectionErrorText')}</p>
  </div>
));

const ResourceLoading = memo(() =>
  <div className="sections-container__loading">
    <Loading>
      <LoadingSpinner/>
      <LoadingText>{getResourceText('resourceSectionsLoading')}</LoadingText>
    </Loading>
  </div>
);

const TeacherResource = ({ resourceId }) => {
  const [sections, setSections] = useState();
  const [resource, setResource] = useState();
  const [error, setError] = useState(false);
  const { getResourcePack, getSection } = useApi();

  useEffect(() => {
    if (resourceId) {
      // get resource packs allowed for user
      const getData = async () => {
        const { status, json } = await getResourcePack(resourceId);

        if (status === 200) {
          const { sections: sectionIds = [], ...rest } = json;
          setResource({ ...rest, sections: sectionIds });
          const sections = await getAllSections({ fetchFunction: getSection, resourceId, sectionIds });

          setSections(sortResourcesByName(sections));
        } else {
          setError(true);
          // need to handle this below
        }
      };
      getData().catch(() => setError(true));
    }
  }, [resourceId, getResourcePack, getSection]);

  return (
    <UserPage title={resource ? resource.resourceName : null}>
      <div className="sections-container">
        {!resource && !error ? <ResourceLoading/> : null}
        {resource ? <Fragment>
          <Link to="/dashboard"
                className="sections-container__heading-link">{getResourceText('backToDashboardLinkText')}</Link>
          {resource?.teacherNotesURL ? <a href={formatForDownloadGoogleDriveURL(resource.teacherNotesURL)} className="sections-container_teacher-notes" target="_blank" rel="noreferrer" download={`${resource.resourceName}-teacher-notes`}>Download teacher notes and suggestions for {resource.resourceName}</a> : null}
          {sections ? <SectionLinks sections={sections} resource={resource}/> : null}
        </Fragment> : null}
        {error ? <ResourceError/> : null}
      </div>
    </UserPage>
  );
};

export const query = graphql`
  query resourceSectionPageQuery {
    dashboardResourcesImage1: file(
      relativePath: { eq: "rossini-with-bongos.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width:240)
      }
    }
    dashboardResourcesImage2: file(
      relativePath: { eq: "tchaikovsky-with-flute.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width:300)
      }
    }
    dashboardResourcesImage3: file(
      relativePath: { eq: "chevalier-with-violin.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width:280)
      }
    }
    dashboardResourcesImage4: file(
      relativePath: { eq: "mozart-with-microphone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width:280)
      }
    }
    dashboardResourcesImage5: file(
      relativePath: { eq: "lully-with-accordion.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width:240)
      }
    }
    dashboardResourcesImage6: file(
      relativePath: { eq: "handel-with-tamborine.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width:150)
      }
    }
    dashboardResourcesImage0: file(
      relativePath: { eq: "wagner-with-french-horn.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width:300)
      }
    }
  }`;

export default TeacherResource;