import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { getData, USER_KEY } from '../lib/store';

export const useUser = () => {
  const user = getData(USER_KEY);

  useEffect(() => {
    const getData = async () => {
      if (!user) {
        await navigate('/login');
      }
    };
    getData().catch((e) => console.log('Error navigating to login'));
  }, [user]);

  return user ?? {};
};

