import React, {FC} from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import { getResourceText } from '../lib/language';

const academyOfBarmyComposersFooter: FC = () => {
  const data = useStaticQuery(query);

  return (
    <div className={classNames('abc-footer-container')}>
      <GatsbyImage
        className="abc-footer-container__image"
        image={getImage(data.footerImage)!}
        title="All composers"
        alt="All composers"
      />
      <span className="abc-footer-container__text">{getResourceText('academyOfBarmyComposers')}</span>
    </div>
  );
};

export const query = graphql`
  query footerQuery {
    footerImage: file(relativePath: { eq: "all-composers.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
  }
`;

export default academyOfBarmyComposersFooter;
